<template>
  <div class="about-components-news-wrapper">
    <div
      v-if="isLoad && newsList.length"
    >
      <div class="news-top">
        <div class="title">
          {{ $t('newsCenter') }}
        </div>
        <div class="big-picture">
          <div
            @click="newsClick(computedListTop[bigpictureActive])"
            class="left-img"
          >
            <img :src="computedListTop[bigpictureActive].cover">
          </div>
          <div class="right-list">
            <div
              :key="index"
              v-for="(item, index) in computedListTop"
              @mouseover="newsmouseover(index)"
              @click="newsClick(item)"
            >
            <div
              class="right-list-left"
            >
              <div>0{{ index + 1 }}</div>
              <div>{{ time(item.show_time) }}</div>
            </div>
              <div class="right-list-right">
                <div>
                  <div>{{ item.title }}</div>
                  <div>{{ item.desction }}</div>
                </div>
                <div>
                  <img src="@/assets/image/about/news-link.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="news-list">
        <div class="news-list-content">
          <div class="title">
            {{ $t('newsMore') }}
          </div>
          <div class="news-list-content-swiper">
            <swiper
              :options="newsSwiperOptions"
            >
              <template
                v-for="(item, index) in computedList"
              >
                <swiper-slide
                  :key="index"
                  class="news-slide"
                  @click.native="newsClick(item)"
                > 
                  <div
                    class="news-slide-img"
                  >
                    <img
                      :src="item.cover"
                    />
                  </div>
                  <div
                    class="news-slide-des"
                  >
                    <div>{{ item.title }}</div>
                    <div>{{ time(item.show_time) }}</div>
                  </div>
                </swiper-slide>
              </template>
            </swiper>
          </div>
          <div class="news-swiper-scrollbar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { formatTime, goGet } from '@/utils'
export default {
  name: 'AboutNews',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      isLoad: false,
      newsList: [],
      bigpictureActive: 0,
      newsSwiperOptions: {
        slidesPerView: 3,
        spaceBetween: 35,
        slidesPerColumn : 2,
        slidesPerColumnFill : 'row',
        scrollbar: {
          el: ".news-swiper-scrollbar",
          hide: false
        }
      }
    }
  },
  watch: {
    '$i18n.locale'() {
      this.bigpictureActive = 0
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    computedList() {
      return this.newsList.filter(list => list.lang === this.$i18n.locale)
    },
    computedListTop() {
      const result = this.newsList.filter(list => list.lang === this.$i18n.locale)
      return result.length >= 3 ? result.slice(0, 3) : result
    }
  },
  methods: {
    getList() {
      goGet('/ewinlight/article/list').then(res => {
        this.isLoad = true
        this.newsList = res.data.map(list => {
          list.cover = list.cover.replace('http://192.168.2.245:1500', process.env.VUE_APP_API)
          return list
        })
      })
    },
    time(date) {
      return formatTime('format', date)
    },
    newsmouseover(index) {
      if (index !== this.bigpictureActive) this.bigpictureActive = index
    },
    newsClick(item) {
      this.$router.push('/article/' + item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.about-components-news-wrapper {
  width: 100%;
  min-height: 50vh;
  overflow: hidden;
  .title {
    margin-top: 80px;
    margin-bottom: 80px;
    color: rgba(34, 34, 34, 1);
    font-size: 42px;
    width: 100%;
    text-align: center;
    line-height: 54px;
    font-weight: bold;
  }
  .big-picture {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    height: 520px;
    overflow: hidden;
    margin-bottom: 90px;
    justify-content: space-between;
    cursor: pointer;
    .left-img {
      width: 49%;
      height: 520px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    .right-list {
      width: 51%;
      height: 520px;
      overflow: hidden;
      &>div {
        display: flex;
        height: 128px;
        overflow: hidden;
        margin-bottom: 68px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        .right-list-left {
          flex-shrink: 0;
          width: 183px;
          padding-left: 58px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-right: 1px solid rgba(235, 235, 235, 1);
          &>div:nth-child(1) {
            font-size: 28px;
            color: #000;
            line-height: 40px;
            border-bottom: 1px solid rgba(215, 215, 215, 1);
            width: 30px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          &>div:nth-child(2) {
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            line-height: 20px;
          }
        }
        .right-list-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          &>div {
            padding-left: 25px;
            &>div {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &>div:nth-child(1) {
              height: 28px;
              line-height: 28px;
              font-weight: bold;
              font-size: 20px;
              color: rgba(0, 0, 0, 1);
            }
            &>div:nth-child(2) {
              height: 28px;
              font-size: 16px;
              color: rgba(102, 102, 102, 1);
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
  .news-list {
    width: 100%;
    overflow: hidden;
    background: rgba(245, 246, 247, 1);
    .news-list-content {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      ::v-deep .news-list-content-swiper {
        margin-bottom: 100px;
        .news-slide {
          overflow: hidden;
          height: 410px;
          background: #fff;
          cursor: pointer;
          .news-slide-img {
            display: flex;
            height: 200px;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
          .news-slide-des {
            padding: 30px 40px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            justify-content: space-between;
            height: 210px;
            &>div {
              &:nth-child(1) {
                color: rgba(34, 34, 34, 1);
                font-size: 24px;
                line-height: 33px;
                max-height: 66px;
                font-weight: bold;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
              &:nth-child(2) {
                font-size: 14px;
                color: rgba(153, 153, 153, 1);
              }
            }
          }
        }
      }
      .news-swiper-scrollbar {
        width: 406px;
        height: 2px;
        background: rgba(230, 230, 230, 1);
        margin: 0 auto;
        margin-bottom: 100px;
      }
    }
  }
}
</style>